import React, { useEffect, useState } from 'react';
import {
    AvatarRankingTable,
    AvatarTeamTable,
    BxhBox,
    CustomTabs,
    InfoBxh,
    InfoSearch,
    InfoTeamsList,
    InfoTitle,
    JoinedTotal,
    TopBXH
} from './EventInfo.styles';
import { Button, Form, Input, PaginationProps, Table, Tabs } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { ColumnsType } from 'antd/es/table';
import { IEvent } from '../../types/IEvent';
import { useRootStore } from '../../providers/RootStoreProvider';
import {
    EventRankingType,
    IGetEventRankingRequest,
    ITeamRanking
} from '../../types/ILeaderBoard';
import { APIResponse } from '../../models/APIResponse';
import { API_STATUS } from '../../configs/APIStatus';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../utils/DateUtils';
import { formatNumber, getAvatarImg } from '../../utils';
import { formatDistance } from '../../utils/FormatUtils';
import './InfoBxh.css';

interface Props {
    eventInfo: IEvent;
}

const InfoBxhSection = observer(({ eventInfo }: Props) => {
    const store = useRootStore();
    const { t } = useTranslation();

    const { configWeb } = store.jsonStore;
    const {currentEvent, rankingData, getEventRanking } = store.eventInfoStore;
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [teamName, setTeamName] = useState('');
    const [dayText, setDayText] = React.useState(
        formatDate('DD/MM', new Date())
    );
    const [rankTop, setRankTop] = useState<ITeamRanking[] | null>(null);
    const [displayBXH, setDisplayBXH] = useState(false);

    const [rankingType, setRankingType] = useState(EventRankingType.Company);

    const isStartEvent = (a: number): boolean => {
        const currentDate = new Date().getTime(); 
        return currentDate >= a;
    }

    const onGetEventRanking = async () => {
        const params: IGetEventRankingRequest = {
            data: {
                day: null,
                evid: eventInfo.evid,
                from: (pageIndex - 1) * pageSize,
                size: pageSize,
                type: 0,
                name: teamName,
                value: rankingType
            }
        };
        const result: APIResponse<IEvent> = await getEventRanking(params);
        console.log(result);
    };

    useEffect(() => {
        onGetEventRanking();
    }, [pageIndex, pageSize, rankingType]);

    useEffect(() => {
       if(isStartEvent(currentEvent?.betm!)) setDisplayBXH(true);
       else setDisplayBXH(false);
    }, [rankingData]);

    useEffect(() => {
        if (rankingData && pageIndex === 1)
            setRankTop(rankingData.list.slice(0, 3));
    }, [rankingData, rankingType]);

    const onSearchTeam = () => {
        setPageIndex(1);
        setPageSize(10);
        onGetEventRanking();
    };

    const items = [
        {
            label: (
                <div className="company">
                    {t('eventInfo:leaderboard.company')}
                </div>
            ),
            key: '1',
            children: (
                <>
                    {rankingType == EventRankingType.Company ? (
                        <div style={{ width: '100%', position: 'relative' }}>
                            <AvatarRankingTable src="https://uprace2.vcdn.vn/web/uprace/images/2023/bxh_cover.png" />
                            <div>
                                <div
                                    className="item lv1-2022 lv1"
                                    style={{ position: 'absolute' }}>
                                    {rankTop && rankTop[0] ? (
                                        <div title={rankTop[0].name}>
                                            <p className="logo">
                                                <img
                                                    src={getAvatarImg(
                                                        rankTop[0].ava
                                                    )}
                                                    alt=""
                                                />
                                            </p>

                                            <div className="details">
                                                <p className="ranking-name">
                                                    {rankTop[0].name}
                                                </p>
                                                <p className="km">
                                                    {formatDistance(
                                                        rankTop[0].dis,
                                                        0
                                                    )}{' '}
                                                    km
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div
                                    className="item lv2-2022 lv2"
                                    style={{ position: 'absolute' }}>
                                    {rankTop && rankTop[1] ? (
                                        <div title={rankTop[1].name}>
                                            <p className="logo">
                                                <img
                                                    src={getAvatarImg(
                                                        rankTop[1].ava
                                                    )}
                                                    alt=""
                                                />
                                            </p>
                                            <div className="details">
                                                <p className="ranking-name">
                                                    {rankTop[1].name}
                                                </p>
                                                <p className="km">
                                                    {formatDistance(
                                                        rankTop[1].dis,
                                                        0
                                                    )}{' '}
                                                    km
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div
                                    className="item lv3-2022 lv3"
                                    style={{ position: 'absolute' }}>
                                    {rankTop && rankTop[2] ? (
                                        <div title={rankTop[2].name}>
                                            <p className="logo">
                                                <img
                                                    src={getAvatarImg(
                                                        rankTop[2].ava
                                                    )}
                                                    alt=""
                                                />
                                            </p>
                                            <div className="details">
                                                <p className="ranking-name">
                                                    {rankTop[2].name}
                                                </p>
                                                <p className="km">
                                                    {formatDistance(
                                                        rankTop[2].dis,
                                                        0
                                                    )}{' '}
                                                    km
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </>
            )
        },
        {
            label: (
                <div className="school">
                    {t('eventInfo:leaderboard.school')}
                </div>
            ),
            key: '3',
            children: (
                <>
                    {rankingType == EventRankingType.School ? (
                        <div style={{ width: '100%', position: 'relative' }}>
                            <AvatarRankingTable src="https://uprace2.vcdn.vn/web/uprace/images/2023/bxh_cover.png" />
                            <div>
                                <div
                                    className="item lv1-2022 lv1"
                                    style={{ position: 'absolute' }}>
                                    {rankTop && rankTop[0] ? (
                                        <div title={rankTop[0].name}>
                                            <p className="logo">
                                                <img
                                                    src={getAvatarImg(
                                                        rankTop[0].ava
                                                    )}
                                                    alt=""
                                                />
                                            </p>

                                            <div className="details">
                                                <p className="ranking-name">
                                                    {rankTop[0].name}
                                                </p>
                                                <p className="km">
                                                    {formatDistance(
                                                        rankTop[0].dis,
                                                        0
                                                    )}{' '}
                                                    km
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div
                                    className="item lv2-2022 lv2"
                                    style={{ position: 'absolute' }}>
                                    {rankTop && rankTop[1] ? (
                                        <div title={rankTop[1].name}>
                                            <p className="logo">
                                                <img
                                                    src={getAvatarImg(
                                                        rankTop[1].ava
                                                    )}
                                                    alt=""
                                                />
                                            </p>
                                            <div className="details">
                                                <p className="ranking-name">
                                                    {rankTop[1].name}
                                                </p>
                                                <p className="km">
                                                    {formatDistance(
                                                        rankTop[1].dis,
                                                        0
                                                    )}{' '}
                                                    km
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div
                                    className="item lv3-2022 lv3"
                                    style={{ position: 'absolute' }}>
                                    {rankTop && rankTop[2] ? (
                                        <div title={rankTop[2].name}>
                                            <p className="logo">
                                                <img
                                                    src={getAvatarImg(
                                                        rankTop[2].ava
                                                    )}
                                                    alt=""
                                                />
                                            </p>
                                            <div className="details">
                                                <p className="ranking-name">
                                                    {rankTop[2].name}
                                                </p>
                                                <p className="km">
                                                    {formatDistance(
                                                        rankTop[2].dis,
                                                        0
                                                    )}{' '}
                                                    km
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </>
            )
        },
        {
            label: <div className="fc">{t('eventInfo:leaderboard.club')}</div>,
            key: '2',
            children: (
                <>
                    {rankingType == EventRankingType.Club ? (
                        <div style={{ width: '100%', position: 'relative' }}>
                            <AvatarRankingTable src="https://uprace2.vcdn.vn/web/uprace/images/2023/bxh_cover.png" />
                            <div>
                                <div
                                    className="item lv1-2022 lv1"
                                    style={{ position: 'absolute' }}>
                                    {rankTop && rankTop[0] ? (
                                        <div title={rankTop[0].name}>
                                            <p className="logo">
                                                <img
                                                    src={getAvatarImg(
                                                        rankTop[0].ava
                                                    )}
                                                    alt=""
                                                />
                                            </p>

                                            <div className="details">
                                                <p className="ranking-name">
                                                    {rankTop[0].name}
                                                </p>
                                                <p className="km">
                                                    {formatDistance(
                                                        rankTop[0].dis,
                                                        0
                                                    )}{' '}
                                                    km
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div
                                    className="item lv2-2022 lv2"
                                    style={{ position: 'absolute' }}>
                                    {rankTop && rankTop[1] ? (
                                        <div title={rankTop[1].name}>
                                            <p className="logo">
                                                <img
                                                    src={getAvatarImg(
                                                        rankTop[1].ava
                                                    )}
                                                    alt=""
                                                />
                                            </p>
                                            <div className="details">
                                                <p className="ranking-name">
                                                    {rankTop[1].name}
                                                </p>
                                                <p className="km">
                                                    {formatDistance(
                                                        rankTop[1].dis,
                                                        0
                                                    )}{' '}
                                                    km
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div
                                    className="item lv3-2022 lv3"
                                    style={{ position: 'absolute' }}>
                                    {rankTop && rankTop[2] ? (
                                       <div title={rankTop[2].name}>
                                            <p className="logo">
                                                <img
                                                    src={getAvatarImg(
                                                        rankTop[2].ava
                                                    )}
                                                    alt=""
                                                />
                                            </p>
                                            <div className="details">
                                                <p className="ranking-name">
                                                    {rankTop[2].name}
                                                </p>
                                                <p className="km">
                                                    {formatDistance(
                                                        rankTop[2].dis,
                                                        0
                                                    )}{' '}
                                                    km
                                                </p>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </>
            )
        }
    ];

    /* --------------------------------- COLUMN --------------------------------- */
    const columns: ColumnsType<any> = [
        {
            title: t('eventInfo:leaderboard.rank'),
            render: (_, record) => <>{record.rank}</>
        },
        {
            title: t('eventInfo:leaderboard.teamName'),
            render: (_, record) => (
                <div className="d-flex gap-2 align-items-center">
                    <AvatarTeamTable src={record.ava} />
                    <span style={{ fontWeight: 'bold' }}>{record.name}</span>
                </div>
            )
        },
        {
            title: `${dayText} (Km)`,
            width: 140,
            render: (_, record) => <>{record.diff}</>
        },
        {
            title: t('eventInfo:leaderboard.totalKM'),
            width: 140,
            render: (_, record) => <>{(record.dis / 1000).toLocaleString()}</>
        }
    ];

    const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
        console.log(pageSize);
        setPageIndex(page);
        setPageSize(pageSize);
    };

    const onChangeRankingType = (value: any) => {
        setPageIndex(1);
        setRankingType(value);
    };

    const getRankingTypeLabel = () => {
        if(rankingType == EventRankingType.Company)
            return t('eventInfo:leaderboard.company');
        else if(rankingType == EventRankingType.School)
            return t('eventInfo:leaderboard.school');
        if(rankingType == EventRankingType.Club)
            return t('eventInfo:leaderboard.club');
    }

    const totalTeam: any = formatNumber(rankingData?.total);
    const companyType: any = getRankingTypeLabel();

    if(configWeb && configWeb.openLeaderboard == 1)
    return (
        <InfoBxh>
            <InfoTitle>{t('eventInfo:leaderboard.title')}</InfoTitle>
            <BxhBox>
                <TopBXH>
                    <CustomTabs
                        tabPosition="left"
                        defaultActiveKey="1"
                        onChange={onChangeRankingType}
                        items={items}
                    />
                </TopBXH>
                <JoinedTotal>
                    {t('eventInfo:leaderboard.totalTeamMsg', {
                        totalTeam,
                        companyType
                    })}
                </JoinedTotal>
                <InfoSearch className="w-50">
                    <Form className="d-flex gap-2 w-full">
                        <FormItem className="w-full">
                            <Input
                                size="large"
                                placeholder={t('eventInfo:leaderboard.search')}
                                className="input-search"
                                onChange={(event) =>
                                    setTeamName(event.target.value)
                                }
                            />
                        </FormItem>
                        <FormItem className="w-full">
                            <Button
                                type="primary"
                                onClick={() => onSearchTeam()}
                                className="button-search">
                                {t('eventInfo:leaderboard.search')}
                            </Button>
                        </FormItem>
                    </Form>
                </InfoSearch>
                <InfoTeamsList>
                    <Table
                        dataSource={rankingData?.list}
                        columns={columns}
                        showHeader={true}
                        rowKey={(record) => record.rank.toString()}
                        rowClassName={'font-code cursor-pointer'}
                        pagination={{
                            position: ['bottomCenter'],
                            current: pageIndex,
                            hideOnSinglePage: true,
                            total: rankingData?.total,
                            onChange(page, pageSize) {
                                onPageChange(page, pageSize);
                            }
                        }}
                    />
                </InfoTeamsList>
            </BxhBox>
        </InfoBxh>
    );
    else return null;
});

export default InfoBxhSection;
