import { ComponentType } from 'react';
import pure from 'recompose/pure';

//styles
import {
  EventRuleContainer,
  EventRuleWrapper,
  RankTopImage,
  RankTopTableWrapper,
  RewardCell,
  RuleTitle,
  RuleTitleL1,
  TextIndex,
  TextInfo,
  TextWrapper,
} from '../EventRule.styles';
import { Row } from 'antd';

interface Props {}

export const Rule2024EN: ComponentType<Props> = pure(({}) => {
  return (
    <EventRuleWrapper>
      <EventRuleContainer>
        <RuleTitle>UPRACE 2024</RuleTitle>
        <RuleTitle>PARTICIPATION REGULATION</RuleTitle>
        <RuleTitleL1>I. Definition:</RuleTitleL1>
        <TextWrapper>
          <TextIndex></TextIndex>
          <TextInfo>
            UpRace 2024 (hereinafter referred to as "event") is a FREE community running event for
            everyone on the UpRace web/mobile. Participants (hereinafter referred to as "runners")
            are free to run at arbitrary times and place and actively record the results of running
            on mobile devices (mobile phones, sports watches ...) with target accumulates as much
            running distance as possible.
          </TextInfo>
        </TextWrapper>
        <RuleTitleL1>II. Time:</RuleTitleL1>
        <TextWrapper>
          <TextInfo>
            <strong>1. Event Registration: </strong>
            Registration dates will be announced via the UpRace app, runners can continue registering and joining Teams during the event.
          </TextInfo>

          <TextInfo>
            <strong>2. Expected Competition Time: </strong>
            From 00:00 20/09/2024 to 23:59 13/10/2024 (24 days). The Official Competition Time will be announced via the UpRace app.
          </TextInfo>

          <TextInfo>
            <strong>3. Vietnamese time (GMT + 7)</strong> is used as the standard time zone for the
            event.
          </TextInfo>
        </TextWrapper>
        <RuleTitleL1>III. How to register:</RuleTitleL1>
        <TextWrapper>
          <TextInfo>
            <b>1.</b> Runners download the UpRace app on mobile phones or visit the website:{' '}
            <a href='https://uprace.org'>https://uprace.org </a> and register an account.
            Participants from previous years can log in using their existing accounts.
          </TextInfo>

          <TextInfo>
            <b>2.</b> After successfully registering an account, runners can follow the steps by
            going through the “Community” section then “Event” section on the app and clicking the
            “Join” button to participate in the UpRace 2024 event.
          </TextInfo>

          <TextInfo>
            <b>3.</b> Runners click to join the Team that you want to choose. Each runner's account
            can only select one Team to compete in the event. The runners are not allowed to change
            the Team from the time the event starts at 00:00 20/09/2024 to 23:59 13/10/2024 when the
            event ends.
          </TextInfo>
          <TextInfo>
            <b>4.</b> Runners, who join the event after the event has already started, will still be
            allowed to compete with the others.
          </TextInfo>
          <TextInfo>
            <b>5.</b> The runners then choose to fundraise to a Community Partner that they prefer.
            Each runner's account can only be chosen to fundraise for one Community Partner. The
            runners are not allowed to change their chosen Community Partner during the course of
            the event. There are 3 Community Partner for runners, including:
            <p>
              a. The Center for the Support of the Development of Maternal and Neonatal Care in Viet
              Nam (So Sinh Viet Nam)
            </p>
            <p>b. Vu A Dinh Scholarship Fund (VAD)</p>
            <p>c. The Association for the Support of Vietnamese Disability and Orphans (ASVDO)</p>
          </TextInfo>
        </TextWrapper>
        <RuleTitleL1>IV. Team's Competition Rules: </RuleTitleL1>
        <TextWrapper>
          <TextInfo>
            <b>1.</b> Teams are placed in 3 league tables including:
            <p>a. Table 1: Company Teams</p>
            <p>b. Table 2: Sports Club Teams</p>
            <p>c. Table 3: School Teams</p>
          </TextInfo>
          <TextInfo>
            <b>2.</b> Teams in the same Table will compete against each other and rank based on the
            total running distance of each team member.
          </TextInfo>

          <TextInfo>
            <b>3.</b> Teams, which are established after the event starts, still participate in the
            competition normally.
          </TextInfo>
        </TextWrapper>
        <RuleTitleL1>V. Runners Competition Rules:</RuleTitleL1>
        <TextWrapper>
          <TextInfo>
            <strong>1. Record achievements: </strong>The runners record their achievements every run
            by mobile device (phone, watch with GPS function ...).
          </TextInfo>
          <TextInfo>
            <strong>2. Valid race is counted when committing the following conditions:</strong>
            <p>
              a. Recorded as completed and displayed on the runner' s UpRace account within 72 hours
              from the activity's start time and no later than the last day of the event.
            </p>
            <p>
              b. Express the map clearly with no sign of critical errors due to the error of
              catching GPS signal of mobile devices. The activities which are imported manual
              results, either shared by others, or recorded for different accounts will not be
              validated; In case of running on treadmills, the result must be recorded by fitness
              tracker or smart watch like Garmin; which is able to synchronize with UpRace.
            </p>
            <p>
              c. Activities that are duplicated by recording from multiple devices at a same time
              will be counted as one activity. The priority order of activity’s source will be:
              (Apple Watch, Garmin, Suunto, Coros, Fitbit, Polar) {'>'} UpRace.
            </p>
            <p>
              d. There is a minimum distance of 1km. There is an unlimited maximum limit. Runners
              are allowed to pause the activity while running with the purpose of short break.
            </p>
            <p>
              e. Maximum Average Moving Pace is 4:00 minutes/km and minimum is 15:00 minutes/km,
              which are calculated by the number of minutes to complete 01 km. Split pace of each 1
              km must not be faster than 3:30 minutes/km.
            </p>
            <p>
              f. Races that take place on Sundays during the event period must be completed (before
              24:00) on that day and double-results will be counted on the achievement of that day.
            </p>
          </TextInfo>
          <TextInfo>
            <strong>3. Active days:</strong> The dates of at least one valid run according to the
            above rule.
          </TextInfo>
          <TextInfo>
            <strong>4. Double-day: </strong>Includes Sundays during the event period.
          </TextInfo>

          <TextInfo>
            <strong>5. Complete the contest: </strong>A runner will be certified to complete the
            contest when both of the following conditions are reached:
            <p>a. Must achieve a minimum number of 12 valid days.</p>
            <p>
              b. Must achieve a minimum distance of 60km (including double achievement on Sundays).
              Note: For runners who do not complete the contest (minimum number of 12 valid days and
              minimum distance of 60km), the completed kilometers are still contributed to the team
              and fundraised by the sponsor to a social organization.
            </p>
          </TextInfo>
          <TextInfo>
            <strong>6. Achievement date: </strong>The achievement will be counted on the day when
            activity starts.
          </TextInfo>
        </TextWrapper>
        <RuleTitleL1>VI. Prize: </RuleTitleL1>
        <TextWrapper>
          <TextInfo>
            <strong>1. Completion Certificate:</strong>
          </TextInfo>
          <TextInfo>
            All runners who are qualified to finish the competition will receive a certificate of
            completion of the competition (electronic version) according to the completed km
            milestones including: 60km, 150km, 300km respectively with the titles: Pro runner, Hero
            runner and Angel runner.
          </TextInfo>
          <TextInfo>
            <strong>2. In-kind Award:</strong>
          </TextInfo>
          <TextInfo>
            <strong>2.1 Achievement Award for Team: </strong>
          </TextInfo>
          <TextInfo>
            10 teams with the best overall performance in each league table will receive the Team
            reward. Team reward, which includes UpRace Winning Team 2024 T-shirt will be given to:
            <p>a. Runners that complete the contest.</p>
            <p>b. And belong to the winning teams, according to the following table:</p>
          </TextInfo>
          <RankTopTableWrapper>
            <Row>
              <RewardCell xl={6} md={6} xs={6}></RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                <strong>Company</strong>
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                <strong>Sport club</strong>
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                <strong>School</strong>
              </RewardCell>
            </Row>
            <Row>
              <RewardCell xl={6} md={6} xs={6}>
                Rank 1
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 300 runners
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 250 runners
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 200 runners
              </RewardCell>
            </Row>
            <Row>
              <RewardCell xl={6} md={6} xs={6}>
                Rank 2
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 250 runners
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 200 runners
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 150 runners
              </RewardCell>
            </Row>
            <Row>
              <RewardCell xl={6} md={6} xs={6}>
                Rank 3
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 200 runners
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 150 runners
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 100 runners
              </RewardCell>
            </Row>
            <Row>
              <RewardCell xl={6} md={6} xs={6}>
                Rank 4 and 5
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 150 runners
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 100 runners
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 50 runners
              </RewardCell>
            </Row>
            <Row>
              <RewardCell xl={6} md={6} xs={6}>
                Rank 6,7,8,9 and 10
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 50 runners
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 50 runners
              </RewardCell>
              <RewardCell xl={6} md={6} xs={6}>
                Top 50 runners
              </RewardCell>
            </Row>
          </RankTopTableWrapper>

          <p style={{ paddingTop: 20 }} />
          <TextInfo>
            <strong>2.2 Individual awards:</strong>
            <br />
            UpRace T-shirt is awarded to the Top 100 male/female runners with the highest
            performance (excluding runners who received the top 10 awards in the above table)
          </TextInfo>
          <TextInfo>
            <strong>3. Other prizes: </strong> Prizes for the highest performing team in each group
            (Top 1):
            <p>a. Certification of winning team achievement (electronic version).</p>
            <p>b. Posts honoring the Top 1 team in each group on UpRace fanpage.</p>
          </TextInfo>
        </TextWrapper>
        <RuleTitleL1>VII. Fundraising for the community: </RuleTitleL1>
        <TextWrapper>
          <TextInfo>
            Businesses, organizations, and individuals can participate in fundraising for the
            community through UpRace 2024 in various ways (through sponsorship packages, conversion
            of actual achievements, community appeals, etc.). All raised funds will be transferred
            directly to our social partners.
          </TextInfo>
          <TextInfo>
            Please contact the Organizing Committee via email at{' '}
            <a href='mailto:info@uprace.org'>info@uprace.org</a> for consultation on sponsorship
            packages and fundraising methods.
          </TextInfo>
        </TextWrapper>
        <RuleTitleL1>
          VIII. Rights of Organizing Committee and Team Administration Board:
        </RuleTitleL1>
        <TextWrapper>
          <TextInfo>
            <strong>1. Complaints and Reports: </strong>
            The Organizing Committee (OC) and Team Administration Board (TAB) will review and
            respond to any complaints or reports from participants during the UpRace 2024 event on
            the UpRace app. The TB's decision will be final for all arising complaints.
          </TextInfo>
          <TextInfo>
            <strong>2. Penalties: </strong>
            The OC has measures in place to prevent and detect any cheating. Additionally, the OC
            encourages participants to maintain the highest level of fair play and self-discipline.
            If any cheating is detected, the participant will be disqualified from the competition,
            and all achieved results will be voided.
          </TextInfo>
          <TextInfo>
            <strong>3. OC's Rights: </strong>
            The OC has full authority to determine whether a Team or participant is eligible to
            compete. The OC also reserves the right to stop a competition and cancel the results of
            a Team or participant if any cheating or rule violations are detected, as well as the
            right to resolve any complaints.
          </TextInfo>
        </TextWrapper>
        <RuleTitleL1>IX. Rights of the Team’s Board of Administrators:</RuleTitleL1>
        <TextWrapper>
          <TextInfo>
            <strong>1. Team Management: </strong>
            The Administrator (Team leader) is responsible for supervising and monitoring the Team's
            activities, appointing or revoking the Vice Administrators (Deputy team leaders)'
            authority and sending notifications to all members. The Administrator has the right to
            block or unblock the activities of any team member. Members whose activities are locked
            will not have their achievements recorded during the entire event period.
          </TextInfo>
          <p style={{ color: 'red', marginLeft: 10 }}>
            <strong>Note: Team Removal is not allowed during the UpRace 2024 event.</strong>
          </p>
          <TextInfo>
            <strong>2. Adjust Team information: </strong>
            The Administrator can change the Team profile picture, cover photo, biography, or
            description information in the Community tab {'>'} Club section.
          </TextInfo>
          <TextInfo>
            <strong>3. Team member Management: </strong>
            If individual problems or fraud are detected, the Administrator can remove members from
            the Team in the Community tab {'>'} Club section.
          </TextInfo>
          <TextInfo>
            <strong>4. Group Management: </strong>
            The Administrator approves requests to create Groups and manages all groups in the Team.
            When detecting problems or fraud, they can request an explanation or delete the Group
            completely.
          </TextInfo>
          <p>
            <i>
              Note: Each Team has only{' '}
              <strong>1 Administrator. The number of Vice Administrators is unlimited.</strong> The
              Vice Administrator has the same rights and obligations as the Administrator, except
              they cannot cancel or revoke themselves or other Vice Administrators' rights.
            </i>
          </p>
        </TextWrapper>
        <RuleTitleL1>X. Rights of Group Leaders in Teams:</RuleTitleL1>
        <TextWrapper>
          <TextInfo>
            <strong>1. Group member management: </strong>
            The Group Leader reviews participation requests and can remove Members from the Group.
          </TextInfo>
          <TextInfo>
            <strong>2. Adjust Group information: </strong>
            The Group Leader has the right to modify and update the name, profile picture,
            description, and introduction of the Group. Note: Group cover photos are set according
            to the Team cover photo and cannot be changed.
          </TextInfo>
          <TextInfo>
            <strong>3. Group Removal & Transfer Group Leader rights: </strong>
            If the Group cannot continue to operate, the Group Leader has the right to delete the
            Group or appoint another member to be the Group Leader and leave the Group.
          </TextInfo>
        </TextWrapper>
        <RuleTitleL1>XI. Report/Complaint in UpRace 2024: </RuleTitleL1>
        During UpRace 2024, runners can report or complain about activities in two ways:
        <TextWrapper>
          <TextInfo>
            <strong>Method 1: Create a Support Request/Activity Complaint:</strong>
            Open the UpRace mobile app {'>'} Go to the Home page {'>'} Tap the Report/Complain
            button on the left (icon with a ?! symbol) {'>'} Tap the plus icon in the bottom right
            corner of the screen {'>'} Create a new ticket for UpRace 2024 or View Instructions for
            UpRace 2024.
          </TextInfo>
          <TextInfo>
            <strong>Method 2: </strong>
            <ul>
              <li>
                <strong>Complain about Your Invalid Activity:</strong> Open the UpRace mobile app{' '}
                {'>'} Go to the Community section {'>'} Select the UpRace 2024 event {'>'} Go to the
                You tab {'>'} Select 'View your invalid activities' {'>'} Choose the activity you
                want to complain about and tap the Complain button in the right corner of the
                activity.
              </li>
              <li>
                <strong>Report Suspicious Activity of Another Runner:</strong>
                Open the UpRace mobile app {'>'} Go to the Community section {'>'} Select the UpRace
                2024 event {'>'} Go to the Leaderboard or Team tab {'>'} Select a runner from any
                Team/Group {'>'} Select Select 'View your invalid activities' {'>'} Choose the
                activity you want to report and tap the Report button in the right corner of the
                activity.
              </li>
            </ul>
          </TextInfo>
        </TextWrapper>
        <p style={{ paddingBottom: 30 }} />
      </EventRuleContainer>
    </EventRuleWrapper>
  );
});
