import 'swiper/swiper.min.css';

import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// Import Swiper React components
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { useRootStore } from '../../../providers/RootStoreProvider';
import { Link } from 'react-router-dom';
import { APP_LANGUAGE } from '../../../configs/GlobalConst';

export const HeroSection: FC = observer(({}) => {
  const store = useRootStore();
  const { t } = useTranslation();

  const { language } = store.globalStore;

  return (
    <div>
      <section className='hero-section'>
        <Swiper
          className='heroSwiper'
          lazy={true}
          loop={false}
          navigation={true}
          autoplay={
            {
              //delay: 3000
            }
          }
          modules={[Navigation, Autoplay]}
        >
          <SwiperSlide>
            <div className='card card-hero'>
              <Link to={'/event'}>
                <img
                  src={
                    language === APP_LANGUAGE.VIETNAMES
                      ? 'https://uprace2.vcdn.vn/web/uprace/images/banners/banner1.jpg'
                      : 'https://uprace2.vcdn.vn/web/uprace/images/banners/banner1-en.jpg'
                  }
                  alt='Banner'
                  className='img-fluid banner-img bg-hero card-img'
                />
              </Link>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='card card-hero'>
            <Link to={'/event'}>
              <img
                src={
                  language === APP_LANGUAGE.VIETNAMES
                    ? 'https://uprace2.vcdn.vn/web/uprace/images/banners/banner2.jpg'
                    : 'https://uprace2.vcdn.vn/web/uprace/images/banners/banner2-en.jpg'
                }
                alt='Banner'
                className='img-fluid banner-img bg-hero card-img'
              />
              </Link>
            </div>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div className='card card-hero'>
              <img
                src='https://uprace2.vcdn.vn/web/uprace/images/2023/banner_top2.png'
                alt='Banner'
                className='img-fluid banner-img bg-hero card-img'
              />
            </div>
          </SwiperSlide> */}
        </Swiper>
      </section>
    </div>
  );
});
