import styled from 'styled-components';
import { device } from '../../configs/MediaQuery';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { AvatarImage } from '../../components/User/AvatarImage';
import { EventImage } from '../../components/Event/EventImage';
import { Tabs } from 'antd';

export const EventInfoWrapper = styled.section``;

export const EventInfoContainer = styled.div`
  display: flex;
  max-width: 1410px;
  margin: 0 auto;
  padding: 30px 60px 60px;

  @media ${device.mobileS} {
    flex-direction: column;
    padding: 20px; /* Optional: Adjust padding for mobile */
  }
  @media ${device.laptop} {
    flex-direction: row;
  }
`;
export const LeftSide = styled.div`
  flex-grow: 1;
  
  @media ${device.mobileS} {
    width: 100%;
  }
  @media ${device.laptop} {
    width: calc(100% - 400px);
  }
`;
// background-image: url(${bgBanner}) !important;

interface InfoBannerEventProps {
  backgroundImage: string;
}
export const InfoBannerEvent = styled.div<InfoBannerEventProps>`
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: 50%;
  display: flex;
  align-items: center;
  flex-flow: column;
  position: relative;
  height: 304px;
  border-radius: 12px;
`;
export const TxtUprace = styled.div`
  padding-top: 42px;
`;
export const DateFinal = styled.div`
  display: flex;
  place-content: center;
  margin-top: 25px;
`;
export const TxtBanner = styled.div`
  text-align: center;
  padding-top: 40px;
  line-height: 45px;
  font-family: 'SVN-BigNoodleTitling';
  color: #d54811;
  font-size: 35px;
`;
export const InfoAchieve = styled.div`
  margin-top: 32px;
`;
export const InfoTitle = styled.h2`
  line-height: 36px;
  font-size: 28px;
  color: #000;
  font-weight: 700;
`;
export const AchieveBox = styled.div`
  margin-top: 16px;
  padding: 34px 0 24px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;
export const Km = styled.div`
  padding: 0 60px;
  font-size: 18px;
`;
export const Lb = styled.span``;
export const Num = styled.span`
  margin: 0 2px 0 6px;
  color: #0046c2;
  font-size: 36px;
  font-weight: 600;
`;
export const Unit = styled.span``;
export const ProcessContainer = styled.div`
  position: relative;
  margin: 38px 60px 94px;
`;
export const JoinedList = styled.ul`
  display: flex;
  margin: 0 66px;
`;
export const Team = styled.li`
  padding-right: 12px;
  position: relative;
  width: 50%;
  list-style-type: none;
`;
export const JoinListBox = styled.div`
  padding: 48px 12px 24px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
`;
interface IconProps {
  icon: string;
}
export const Ico = styled.span<IconProps>`
  background: #e9eeff;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -32px;
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 1px solid #0046c2;

  &::before {
    content: '${(props) => props.icon}';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 30px;
    color: #0046c2;
  }
`;
export const NumJoinListBox = styled.p`
  line-height: 30px;
  font-size: 24px;
  font-weight: 700;
`;
export const LbJoinListBox = styled.p`
  line-height: 22px;
  margin-top: 8px;
  font-size: 18px;
`;

export const Group = styled.li`
  padding-left: 12px;
  position: relative;
  width: 50%;
  list-style-type: none;
`;

export const InfoBxh = styled.div`
  margin-top: 32px;
`;
export const BxhBox = styled.div`
  
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-top: 16px;

  @media ${device.mobileL} {
    padding: 5px;
  }

  @media ${device.laptop} {
    padding: 24px;
  }
`;
export const TopBXH = styled.div`
  position: relative;
  display: flex;
`;
export const JoinedTotal = styled.div`
  line-height: 27px;
  margin-top: 16px;
  font-size: 20px;
  text-align: center;
  font-weight: 600;

  @media ${device.mobileL} {
    padding-left: 5px;
    font-size: 14px;
  }

  @media ${device.laptop} {
    padding-left: 152px;
    font-size: 20px;
  }
`;
export const InfoSearch = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-top: 32px;
`;
export const InfoTeamsList = styled.div`
  min-height: 300px;
  position: relative;
  border: 1px solid #dbdfe2;
  border-radius: 7px;
`;

export const RightSide = styled.div`
  flex: none;
  @media ${device.mobileL} {
    width: 100%;
    margin-left: 0;
    margin-top: 20px; /* Optional: Add margin for spacing */
  }

  @media ${device.laptop} {
    width: 360px;
    margin-left: 40px;
  }
`;
export const InfoAboutEvent = styled.div`
  
`;

export const RuleBox = styled.div`
  margin-top: 16px;
  padding: 16px 16px 24px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border-radius: 7px;
`;
export const RulesTitle = styled.h3`
  line-height: 26px;
  color: #000;
  font-size: 18px;
  fonr-weight: 600;
`;
export const RulesList = styled.ul`
  margin-top: 16px;
  padding-left: 0px;
`;
export const Speed = styled.li`
  position: relative;
  line-height: 22px;
  padding: 1px 0 1px 32px;
  color: #797979;
  list-style-type: none;

  &::before {
    content: '\f70c';
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    color: #727f8e;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }
`;
export const Road = styled.li`
  position: relative;
  line-height: 22px;
  padding: 1px 0 1px 32px;
  color: #797979;
  list-style-type: none;
  margin-top: 16px;

  &::before {
    content: '\f018';
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    color: #727f8e;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }
`;
export const Done = styled.li`
  position: relative;
  line-height: 22px;
  padding: 1px 0 1px 32px;
  color: #797979;
  list-style-type: none;
  margin-top: 16px;

  &::before {
    content: '\f14a';
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    color: #727f8e;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }
`;
export const Team2 = styled.li`
  position: relative;
  line-height: 22px;
  padding: 1px 0 1px 32px;
  color: #797979;
  list-style-type: none;
  margin-top: 16px;

  &::before {
    content: '\f0c0';
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    color: #727f8e;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }
`;
export const X2 = styled.li`
  position: relative;
  line-height: 22px;
  padding: 1px 0 1px 32px;
  color: #797979;
  list-style-type: none;
  margin-top: 16px;

  &::before {
    content: 'x2';
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 24px;
    height: 24px;
    color: #727f8e;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
`;

export const InfoTcxh = styled.div`
  width: 100%;
  margin-top: 32px;
`;
export const TcxhList = styled.ul`
  padding-left: 0px;
`;
export const TcxhListItem = styled.li`
  list-style-type: none;
  background: #fbfbfb;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  margin-top: 16px;
  padding: 12px;
  border-radius: 7px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
`;
export const ListItemLogo = styled.p`
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  margin-right: 8px;
  border-radius: 8px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 0px;
`;

export const ListItemDetails = styled.div`
  flex-grow: 1;
  width: calc(100% - 72px);
`;
export const ListItemDetailsName = styled.p`
  line-height: 24px;
  font-size: 16px;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 0px;
`;
export const ListItemDetailsMoney = styled.p`
  line-height: 24px;
  margin-top: 6px;
  color: #0046c2;
  margin-bottom: 0px;
`;
export const ListItemDetailsMoneyNum = styled.span`
  font-size: 16px;
  font-weight: 700;
`;

export const ListItemDetailsMoneyUnit = styled.span`
  font-size: 14px;
`;

interface TabCompanyProps {
  bg: string;
}
export const TabCompany = styled.div<TabCompanyProps>`
  background-size: cover;
  background-position: 50%;
  position: relative;
  height: 474px;
  border-radius: 12px;
  overflow: hidden;
  background-image: url(${(props) => props.bg}) !important;
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-grow: 1;
`;

interface TabCompanyItemProps {
  left: string;
  top: string;
}
export const TabCompanyItem = styled.div<TabCompanyItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  position: absolute;
  width: 120px;
  left: calc(50% - 58px);
  top: 130px;
`;

export const TabCompanyItemLogo = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 94px;
  border-radius: 50%;
  overflow: hidden;
`;
export const TabCompanyItemDetails = styled.div`
  width: 100%;
  margin-top: 15px;
`;
export const TabCompanyItemDetailsName = styled.p`
  line-height: 28px;
  font-family: 'SVN-BigNoodleTitling';
  color: #9f3106;
  font-size: 24px;
  text-align: center;
`;
export const TabCompanyItemDetailsKm = styled.p`
  line-height: 28px;
  font-family: 'SVN-BigNoodleTitling';
  color: #9f3106;
  font-size: 24px;
  text-align: center;
`;

export const AvatarTeamTable = styled(AvatarImage)`
  border-radius: 50%;
  object-fit: cover;
  @media ${device.mobileS} {
    width: 40px;
    height: 40px;
  }
  @media ${device.tablet} {
    width: 40px;
    height: 40px;
  }
  @media ${device.laptop} {
    width: 40px;
    height: 40px;
  }
`;
export const AvatarRankingTable = styled(EventImage)`
  object-fit: cover;
  background-size: cover;
  background-position: 50%;
  position: relative;
  height: 474px;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
`;

export const CustomTabs = styled(Tabs)`
  .ant-tabs-nav::before {
    border-right: none !important;
  }

  .ant-tabs-ink-bar {
    display: none !important;
  }

  @media ${device.mobileS} {
    display: flex;
    flex-direction: column;
  }
  @media ${device.laptop} {
    display: flex;
    flex-direction: row;
  }
`;
